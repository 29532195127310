<template>
    <h4>enen</h4>
</template>

<script>
    export default {
        name: "management"
    }
</script>

<style scoped>

</style>